import React, { useEffect, useState, useCallback, useRef, memo } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { useStateValue } from "../../state"
import SnackbarComponent from "../reusable/SnackbarComponent"
import AutocompleteWithSelectAll from "./AutocompleteWithSelectAll"
import ListEditor from "./ListEditor"
import FormSkeleton from "../reusable/FormSkeleton"
import AlertPrompt from "../reusable/AlertPrompt"
import Changelog from "../changelog/Changelog"
import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"
import {
  getItem,
  getItems,
  postItem,
  putItem,
  deleteItem,
  wpSync,
  getItemsAsync,
} from "../../helpers/ItemHelper"
import {
  getMenuItem,
  getFields,
  validateTextField,
  validatePhoneNumber,
} from "../../helpers/GeneralHelper"
import useDebounce from "../../helpers/useDebounce"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Autocomplete from "@mui/material/Autocomplete"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CheckIcon from "@mui/icons-material/Check"
import CloudSyncIcon from "@mui/icons-material/CloudSync"
import { Constants } from "../../config"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import { StyledEngineProvider } from "@mui/material/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60ch",
  },
  textField: {
    width: "100%",
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
  modifiedInput: {
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 5,
      bottom: 0,
      width: 3,
      margin: theme.spacing(2, 0),
      backgroundColor: theme.colors.primary,
    },
  },
  sectionHeading: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
}))

// eslint-disable-next-line react/display-name
const MemoizedAutocompleteWithSelectAll = memo((props) => {
  const { options, label, getOptionLabel, selectedValues, onChange } = props
  const handleClearOptions = () => onChange([])
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      onChange(options)
    } else {
      handleClearOptions()
    }
  }
  return (
    <AutocompleteWithSelectAll
      items={options}
      getOptionLabel={getOptionLabel}
      selectedValues={selectedValues || null}
      label={label}
      selectAllLabel="Select all"
      onToggleOption={onChange}
      onClearOptions={handleClearOptions}
      onSelectAll={handleSelectAll}
    />
  )
})

MemoizedAutocompleteWithSelectAll.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  getOptionLabel: PropTypes.func,
  selectedValues: PropTypes.array,
  onChange: PropTypes.func,
}

// eslint-disable-next-line react/display-name
const MemoizedAutocomplete = memo(
  (props) => {
    return <Autocomplete {...props} />
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value
  }
)

// eslint-disable-next-line react/display-name
const MemoizedDatePicker = memo(
  (props) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment.utc}>
        <DatePicker {...props} />
      </LocalizationProvider>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value
  }
)

// eslint-disable-next-line react/display-name
const MemoizedSelect = memo(
  (props) => {
    return <Select {...props}></Select>
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value
  }
)

// eslint-disable-next-line react/display-name
const MemoizedTextField = memo(
  (props) => {
    const error = validateTextField(props)
    return (
      <TextField {...props} error={error} helperText={error && props.errortext} />
    )
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value
  }
)

MemoizedTextField.propTypes = {
  errortext: PropTypes.string,
}

// eslint-disable-next-line react/display-name
const MemoizedListEditor = memo(
  (props) => {
    return <ListEditor {...props} />
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value
  }
)

function EditForm(props) {
  const { match } = props

  const classes = useStyles()

  const routeParams = useParams()

  const location = useLocation()

  const history = useHistory()

  const [{ appBarContext }, dispatch] = useStateValue()

  const { getAccessTokenSilently, user } = useAuth0()

  const { name: currentUserName } = user

  const [apiUrl, setApiUrl] = useState(undefined),
    [menuItem, setMenuItem] = useState(undefined),
    [backNavUrl, setBackNavUrl] = useState(undefined),
    [isInitialized, setIsInitialized] = useState(false),
    [item, setItem] = useState(undefined),
    [originalItem, setOriginalItem] = useState(undefined),
    [options, setOptions] = useState({}),
    [lookupsLoading, setLookupsLoading] = useState(0),
    [isLoading, setIsLoading] = useState(true),
    [showSnackbar, setShowSnackbar] = useState(false),
    [snackbarProps, setSnackbarProps] = useState({}),
    [fields, setFields] = useState(undefined),
    [fieldSections, setFieldSections] = useState([]),
    [invalidFields, setInvalidFields] = useState({}),
    [alertPromptProps, setAlertPromptProps] = useState(false),
    [displayChangelog, setDisplayChangelog] = useState(false)

  const debouncedItem = useDebounce(item, 500)
  const debouncedInvalidFields = useDebounce(invalidFields, 500)

  const itemRef = useRef()
  itemRef.current = item

  const originalItemRef = useRef()
  originalItemRef.current = originalItem

  const fieldsRef = useRef()
  fieldsRef.current = fields

  const backNavUrlRef = useRef()
  backNavUrlRef.current = backNavUrl

  const loadOptions = useCallback(
    (fields) => {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.options) {
          setOptions((prevState) => ({
            ...prevState,
            [field.name]: field.options,
          }))
        }
        if (field.optionsApi) {
          setLookupsLoading((prevValue) => prevValue + 1)

          getAccessTokenSilently().then((token) => {
            getItems(token, field.optionsApi, (data, error) => {
              if (data) {
                if (field.customFilter) {
                  data = field.customFilter(data)
                }

                data = data.map(function (a) {
                  return {
                    title: a[Constants.nameColumn],
                    name: a[Constants.internalNameColumn],
                  }
                })

                setOptions((prevState) => ({
                  ...prevState,
                  [field.name]: data,
                }))

                setLookupsLoading((prevValue) => prevValue - 1)
              } else {
                setLookupsLoading((prevValue) => prevValue - 1)
                setShowSnackbar(true)
                setSnackbarProps({
                  class: classes.errorSnackbar,
                  messageClass: classes.snackbarMessage,
                  closeEvent: handleCloseSnackbar,
                  message: "Sorry, an error occured when loading options",
                  icon: <ErrorOutlineIcon />,
                })
              }
            })
          })
        }
      }
    },
    [classes.errorSnackbar, classes.snackbarMessage, getAccessTokenSilently]
  )

  const initializeItem = useCallback(
    (itemId, fields) => {
      if (apiUrl && menuItem) {
        getAccessTokenSilently().then((token) => {
          getItem(token, apiUrl + itemId, fields, (item, error) => {
            if (item) {
              setIsLoading(false)
              fields
                .filter((f) => {
                  return f.type === "autocomplete" && f.multiple
                })
                .forEach((f) => {
                  item[f.name] = item[f.name] || []
                })

              let storeItems = true
              fields.forEach((field) => {
                // set fields from other tables
                if (field.apiPath) {
                  storeItems = false
                  item[field.name] = ""
                  setIsLoading(true)
                  getAccessTokenSilently().then((token) => {
                    getItems(token, field.apiPath, (data, error) => {
                      if (data) {
                        data
                          .filter((casino) => {
                            return (
                              casino[field.fieldToCompare] === item[field.itemField]
                            )
                          })
                          .forEach((casino) => {
                            item[field.name] += casino[field.fieldToShow] + ", "
                          })

                        setItem(item)
                        setOriginalItem(item)
                        setIsLoading(false)
                      } else {
                        setIsLoading(false)
                        setShowSnackbar(true)
                        setSnackbarProps({
                          class: classes.errorSnackbar,
                          messageClass: classes.snackbarMessage,
                          closeEvent: handleCloseSnackbar,
                          message:
                            "Sorry, an error occurred when loading from other tables",
                          icon: <ErrorOutlineIcon />,
                        })
                      }
                    })
                  })
                }

                if (field.marketPath) {
                  storeItems = false
                  item[field.name] = ""
                  setIsLoading(true)
                  const promises = field.marketPath.map((market, index) => {
                    return getAccessTokenSilently().then((token) => {
                      return new Promise((resolve) => {
                        getItems(token, market.url, (data, error) => {
                          if (data) {
                            if (field.customFilter) {
                              data = field.customFilter(data, item.internal_name)
                            }
                            if (data.length > 0) {
                              resolve({
                                marketName: market.name,
                                result: "Yes",
                                status: data[0][field.statusCol],
                              })
                            } else {
                              resolve({ marketName: market.name, result: "" })
                            }
                          } else {
                            setIsLoading(false)
                            setShowSnackbar(true)
                            setSnackbarProps({
                              class: classes.errorSnackbar,
                              messageClass: classes.snackbarMessage,
                              closeEvent: handleCloseSnackbar,
                              message:
                                "Sorry, an error occurred when loading for Casino in markets",
                              icon: <ErrorOutlineIcon />,
                            })
                            resolve({ marketName: market.name, result: "" }) // Resolve with an empty result on error
                          }
                        })
                      })
                    })
                  })

                  Promise.all(promises).then((results) => {
                    results.forEach(({ marketName, result, status }) => {
                      if (result) {
                        item[field.name] += `${marketName}: ${status};\n`
                      }
                    })

                    setItem(item)
                    setOriginalItem(item)
                    setIsLoading(false)
                  })
                }
              })

              if (storeItems) {
                setItem(item)
                setOriginalItem(item)
              }

              let nameField = fields.filter((f) => {
                return f.nameField
              })
              nameField =
                nameField && nameField.length > 0 ? nameField[0].name : "name"
              dispatch({
                type: "changeAppBar",
                newAppBar: {
                  title: `Editing ${item[nameField]} on ${menuItem.name} ${
                    menuItem.marketLabel ? "(" + menuItem.marketLabel + ")" : ""
                  }`,
                },
              })
            } else {
              setIsLoading(false)
              setShowSnackbar(true)
              setSnackbarProps({
                class: classes.errorSnackbar,
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Sorry, an error occured when loading data",
                icon: <ErrorOutlineIcon />,
              })
            }
          })
        })
      }
    },
    [dispatch, apiUrl, classes, menuItem, getAccessTokenSilently]
  )

  const valueChangedNoEvent = useCallback((value, key) => {
    setItem((prev) => {
      return { ...prev, [key]: value }
    })
  }, [])

  const autocompleteValueChanged = useCallback((event, key, params) => {
    let formattedValue
    if (Array.isArray(params.value)) {
      formattedValue = params.value
    } else {
      formattedValue = params.value ? params.value.name : null
    }

    setItem((prev) => {
      return { ...prev, [key]: formattedValue }
    })

    if (event && typeof event === "object") {
      event.preventDefault()
    }
  }, [])

  const valueChanged = useCallback(
    (event, key) => {
      let formattedValue

      const value = event.target.value
      formattedValue = value

      const isNumber = event.target.type && event.target.type === "number"
      const isDecimal = isNumber && event.target.step && event.target.step < 1
      const isBoolean = event.target.type === "checkbox"

      if (isNumber && !isDecimal) {
        formattedValue = value ? parseInt(value) : ""
      } else if (isBoolean) {
        formattedValue = event.target.checked ? 1 : 0
      }

      // Validate min and max number limits
      const minLimit = event.target.min
      const maxLimit = event.target.max

      const invalid = Boolean(
        (event &&
          event.target.attributes &&
          event.target.attributes["validatephone"] &&
          !validatePhoneNumber(formattedValue)) ||
          (isNumber &&
            !isDecimal &&
            formattedValue &&
            ((!!minLimit && formattedValue < minLimit) ||
              (!!maxLimit && formattedValue > maxLimit))) ||
          (isDecimal &&
            formattedValue &&
            ((!!minLimit && parseFloat(formattedValue) < minLimit) ||
              (!!maxLimit && parseFloat(formattedValue) > maxLimit)))
      )

      const tempInvalidFields = invalidFields
      if (invalid) {
        tempInvalidFields[key] = true
        setInvalidFields(tempInvalidFields)
      } else if (invalidFields.hasOwnProperty(key)) {
        delete tempInvalidFields[key]
        setInvalidFields(tempInvalidFields)
      }

      setItem((prev) => {
        return { ...prev, [key]: formattedValue }
      })

      event.preventDefault()
    },
    [invalidFields]
  )

  const checkEmptyFieldsWarning = useCallback(
    (newItem, callback) => {
      if (menuItem.editFormEmptyFieldsWarning) {
        const emptyFields = []
        fields
          .filter((f) => {
            return (
              !f.hidden &&
              f.type !== "boolean" &&
              !f.headingTitle &&
              (!menuItem.editFormEmptyFieldsWarning.ignoreFields ||
                menuItem.editFormEmptyFieldsWarning.ignoreFields.indexOf(f.name) < 0)
            )
          })
          .forEach((f) => {
            if (
              !newItem[f.name] ||
              (Array.isArray(newItem[f.name]) && newItem[f.name].length < 1)
            ) {
              emptyFields.push(f)
            }
          })

        if (emptyFields.length > 0) {
          setAlertPromptProps({
            open: true,
            title: menuItem.editFormEmptyFieldsWarning.title,
            content: (
              <>
                {menuItem.editFormEmptyFieldsWarning.content}
                <br />
                <br />
                <ul>
                  {emptyFields.map((e) => (
                    <li key={"missing-" + e.name}>{e.title}</li>
                  ))}
                </ul>
              </>
            ),
            cancelLabel: "Cancel",
            continueLabel: "Continue",
            cancelEvent: () => {
              setAlertPromptProps(false)
            },
            continueEvent: () => {
              setAlertPromptProps(false)
              callback()
            },
          })
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    [menuItem, fields]
  )

  const createItem = useCallback(() => {
    const dataType = routeParams.type
    const newItem = Object.assign({}, itemRef.current)

    checkEmptyFieldsWarning(newItem, () => {
      dispatch({
        type: "changeAppBar",
        newAppBar: { isSaving: true },
      })
      getAccessTokenSilently().then((token) => {
        postItem(
          token,
          apiUrl,
          newItem,
          currentUserName,
          fieldsRef.current,
          (results) => {
            dispatch({
              type: "changeAppBar",
              newAppBar: { isSaving: false, saveEnabled: false },
            })

            if (results && !results.errno) {
              setShowSnackbar(true)
              setSnackbarProps({
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Item has been created",
                icon: <CheckIcon />,
                autoHideDuration: 1500,
              })
              let itemName = ""
              let market = ""
              let isNew = false

              if (dataType === "casinolicenses") {
                itemName = results.items[0].casino
              } else if (dataType === "gamestudiolicenses") {
                itemName = results.items[0].game_studio
              } else {
                itemName = results.items[0].internal_name
              }

              if (
                dataType !== "affiliateplatforms" ||
                dataType !== "operators" ||
                dataType !== "casinos"
              ) {
                setShowSnackbar(true)
                setSnackbarProps({
                  messageClass: classes.snackbarMessage,
                  message: "Sync into WP in process",
                  icon: <CloudSyncIcon />,
                })
                wpSync(dataType, itemName, market, isNew, (syncResults) => {
                  console.log("Wp sync create")
                  if (
                    (syncResults.status && syncResults.status === "new") ||
                    syncResults.status === "updated"
                  ) {
                    setShowSnackbar(true)
                    setSnackbarProps({
                      messageClass: classes.snackbarMessage,
                      closeEvent: handleCloseSnackbar,
                      message: syncResults.message,
                      icon: <CheckIcon />,
                      autoHideDuration: 1500,
                    })
                  } else {
                    console.log("Data sync error: ", syncResults)
                    setShowSnackbar(true)
                    setSnackbarProps({
                      class: classes.errorSnackbar,
                      messageClass: classes.snackbarMessage,
                      closeEvent: handleCloseSnackbar,
                      message: "Sync Error: " + syncResults.message,
                      icon: <ErrorOutlineIcon />,
                    })
                  }
                  setTimeout(() => {
                    history.push(backNavUrlRef.current)
                  }, 1500)
                })
              }
            } else {
              console.log("error: ", results)
              setShowSnackbar(true)
              setSnackbarProps({
                class: classes.errorSnackbar,
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Sorry, an error occured when saving",
                icon: <ErrorOutlineIcon />,
              })
            }
          }
        )
      })
    })
  }, [
    dispatch,
    apiUrl,
    classes,
    history,
    backNavUrlRef,
    currentUserName,
    checkEmptyFieldsWarning,
    getAccessTokenSilently,
  ])

  const deleteItemClicked = useCallback(() => {
    const dataType = routeParams.type
    let nameField = fieldsRef.current.filter((f) => {
      return f.nameField
    })
    nameField = nameField && nameField.length > 0 ? nameField[0] : false

    const name =
      itemRef.current[nameField.name] ||
      itemRef.current[Constants.internalNameColumn] ||
      ""

    setAlertPromptProps({
      open: true,
      title: "Please confirm",
      content: (
        <>
          <p>
            Are you sure you want to delete: <strong>{name}</strong>?
          </p>
          <p>
            <strong>This cannot be undone.</strong>
          </p>
        </>
      ),
      cancelLabel: "Cancel",
      continueLabel: "Continue",
      cancelEvent: () => {
        setAlertPromptProps(false)
      },
      continueEvent: () => {
        setAlertPromptProps(false)
        getAccessTokenSilently().then((token) => {
          deleteItem(token, apiUrl + itemRef.current.id, (results) => {
            dispatch({
              type: "changeAppBar",
              newAppBar: { isSaving: false, saveEnabled: false },
            })
            if (results && !results.errno) {
              setShowSnackbar(true)
              setSnackbarProps({
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: name + " has been deleted",
                icon: <CheckIcon />,
                autoHideDuration: 2000,
              })

              if (
                dataType === "casinolicenses" ||
                dataType === "gamestudiolicenses"
              ) {
                setShowSnackbar(true)
                setSnackbarProps({
                  messageClass: classes.snackbarMessage,
                  message: "Sync into WP in process",
                  icon: <CloudSyncIcon />,
                })
                wpSync(dataType, name, "", false, (syncResults) => {
                  if (syncResults.status && syncResults.status === "updated") {
                    setShowSnackbar(true)
                    setSnackbarProps({
                      messageClass: classes.snackbarMessage,
                      closeEvent: handleCloseSnackbar,
                      message: syncResults.message,
                      icon: <CheckIcon />,
                      autoHideDuration: 1500,
                    })
                  } else {
                    console.log("Data sync error: ", syncResults)
                    setShowSnackbar(true)
                    setSnackbarProps({
                      class: classes.errorSnackbar,
                      messageClass: classes.snackbarMessage,
                      closeEvent: handleCloseSnackbar,
                      message: "Sync Error: " + syncResults.message,
                      icon: <ErrorOutlineIcon />,
                    })
                  }

                  setTimeout(() => {
                    history.push(backNavUrlRef.current)
                  }, 1500)
                })
              }
            } else {
              setShowSnackbar(true)
              setSnackbarProps({
                class: classes.errorSnackbar,
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Sorry, an error occured when deleting item",
                icon: <ErrorOutlineIcon />,
              })
            }
          })
        })
      },
    })
  }, [apiUrl, classes, dispatch, history, backNavUrlRef, getAccessTokenSilently])

  const saveForm = useCallback(() => {
    if (itemRef.current) {
      checkEmptyFieldsWarning(itemRef.current, () => {
        const differingKeys = Object.keys(itemRef.current).filter(
          (k) => itemRef.current[k] !== originalItemRef.current[k]
        )

        const modifiedObject = {}
        for (let i = 0; i < differingKeys.length; i++) {
          const key = differingKeys[i]
          modifiedObject[key] =
            itemRef.current[key] === 0 ? "0" : itemRef.current[key]
        }
        modifiedObject["id"] = itemRef.current.id

        if (itemRef.current.id) {
          dispatch({
            type: "changeAppBar",
            newAppBar: { isSaving: true },
          })

          getAccessTokenSilently().then(async (token) => {
            const dataType = routeParams.type
            const market = routeParams.market
            let itemName = ""
            if (dataType === "casinolicenses") {
              itemName = itemRef.current.casino
            } else if (dataType === "gamestudiolicenses") {
              itemName = itemRef.current.game_studio
            } else {
              itemName = itemRef.current.internal_name
            }

            let sendSync = dataType !== "casinos"

            if (dataType === "casinos" && routeParams.market !== "root") {
              const url = `/onlinecasino/${routeParams.type}/${routeParams.market}/`
              try {
                const data = await getItemsAsync(token, url)
                sendSync = data.some((item) => item.internal_name === itemName)
              } catch (error) {
                console.log("Error fetching data for URL:", url, error)
                sendSync = false
              }
            } else {
              for (const marketCode of Constants.markets) {
                const url = `/onlinecasino/${routeParams.type}/${marketCode}/`

                try {
                  const data = await getItemsAsync(token, url)
                  sendSync = data.some((item) => item.internal_name === itemName)

                  if (sendSync) {
                    break
                  }
                } catch (error) {
                  console.log("Error fetching data for URL:", url, error)
                  sendSync = false
                }
              }
            }

            putItem(
              token,
              apiUrl + modifiedObject.id,
              modifiedObject,
              currentUserName,
              fieldsRef.current,
              (results) => {
                dispatch({
                  type: "changeAppBar",
                  newAppBar: { isSaving: false, saveEnabled: false },
                })
                if (results && !results.errno) {
                  initializeItem(itemRef.current.id, fieldsRef.current)
                  setShowSnackbar(true)
                  setSnackbarProps({
                    messageClass: classes.snackbarMessage,
                    closeEvent: handleCloseSnackbar,
                    message: "Changes has been saved",
                    icon: <CheckIcon />,
                    autoHideDuration: 1500,
                  })

                  if (sendSync) {
                    try {
                      setShowSnackbar(true)
                      setSnackbarProps({
                        messageClass: classes.snackbarMessage,
                        message: "Sync in process",
                        icon: <CloudSyncIcon />,
                      })
                      wpSync(dataType, itemName, market, false, (syncResults) => {
                        if (syncResults.status && syncResults.status === "updated") {
                          setShowSnackbar(true)
                          setSnackbarProps({
                            messageClass: classes.snackbarMessage,
                            closeEvent: handleCloseSnackbar,
                            message: syncResults.message,
                            icon: <CheckIcon />,
                          })
                        } else {
                          console.log("Data sync error: ", syncResults)
                          setShowSnackbar(true)
                          setSnackbarProps({
                            class: classes.errorSnackbar,
                            messageClass: classes.snackbarMessage,
                            closeEvent: handleCloseSnackbar,
                            message: "Sync Error: " + syncResults.message,
                            icon: <ErrorOutlineIcon />,
                          })
                        }
                      })
                    } catch (e) {
                      console.log("Catch error on sync: ", e)
                      setShowSnackbar(true)
                      setSnackbarProps({
                        class: classes.errorSnackbar,
                        messageClass: classes.snackbarMessage,
                        closeEvent: handleCloseSnackbar,
                        message: "Catch error on sync, details in the console",
                        icon: <ErrorOutlineIcon />,
                      })
                    }
                  }
                } else {
                  console.log("error: ", results)
                  setShowSnackbar(true)
                  setSnackbarProps({
                    class: classes.errorSnackbar,
                    messageClass: classes.snackbarMessage,
                    closeEvent: handleCloseSnackbar,
                    message: "Sorry, an error occured when saving",
                    icon: <ErrorOutlineIcon />,
                  })
                }
              }
            )
          })
        }
      })
    }
  }, [
    initializeItem,
    dispatch,
    apiUrl,
    classes.errorSnackbar,
    classes.snackbarMessage,
    currentUserName,
    getAccessTokenSilently,
    checkEmptyFieldsWarning,
  ])

  const changelogClicked = () => {
    setDisplayChangelog(true)
  }

  const changelogFetchFailed = () => {
    setShowSnackbar(true)
    setSnackbarProps({
      class: classes.errorSnackbar,
      messageClass: classes.snackbarMessage,
      closeEvent: handleCloseSnackbar,
      message: "Sorry, an error occured when loading changelog",
      icon: <ErrorOutlineIcon />,
    })
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  const beforeUnloadFunction = useCallback(
    (event) => {
      if (appBarContext.saveEnabled) {
        event.returnValue =
          "You have unsaved changes. Are you sure you want to navigate and loose them?"
      }
    },
    [appBarContext.saveEnabled]
  )

  // Get api url
  useEffect(() => {
    setApiUrl(
      `/${routeParams.level}/${routeParams.type}/${routeParams.market || "root"}/`
    )

    const menuItem = getMenuItem(location)
    setMenuItem(menuItem)
  }, [props])

  // Retrieve fields
  useEffect(() => {
    if (!fields) {
      const tempFields =
        props.location && props.location.fields
          ? props.location.fields
          : getFields(location)
      setFields(tempFields)

      const tempFieldSections = []
      let fieldSectionsIndex = -1
      tempFields
        .filter((f) => {
          return (
            !f.hidden &&
            !f.hideInEdit &&
            (!routeParams.market || f.hideInMarket !== routeParams.market)
          )
        })
        .forEach((f) => {
          if (f.headingTitle) {
            fieldSectionsIndex++
            tempFieldSections.push([f])
          } else {
            tempFieldSections[fieldSectionsIndex].push(f)
          }
        })
      setFieldSections(tempFieldSections)
    }
  }, [props, fields])

  // Retrieve item data
  useEffect(() => {
    if (!isInitialized && fields) {
      let backNavUrl = location.pathname.replace(/\/$/, "").split(/(\/\d*)$/)[0]
      if (backNavUrl.substr(backNavUrl.length - 4) === "/new") {
        backNavUrl = backNavUrl.substr(0, backNavUrl.length - 4)
      }
      setBackNavUrl(backNavUrl)
      const itemId = routeParams.id

      const refreshAction =
        menuItem && menuItem.enableRefreshLookups
          ? () => () => loadOptions(fields)
          : undefined

      const enableChangelog = menuItem && menuItem.enableChangelog

      if (itemId === "new") {
        // Create item
        dispatch({
          type: "changeAppBar",
          newAppBar: {
            title: `Creating an item in ${menuItem.name}`,
            saveAction: () => createItem,
            refreshAction,
            backNavUrl,
            enableCreate: false,
          },
        })
        const blankItem = {}
        fields
          .filter((f) => {
            return f.type === "autocomplete" && f.multiple
          })
          .forEach((f) => {
            blankItem[f.name] = []
          })
        setItem(blankItem)
        setIsLoading(false)
      } else {
        // Edit existing item
        dispatch({
          type: "changeAppBar",
          newAppBar: {
            saveAction: () => saveForm,
            deleteAction: () => deleteItemClicked,
            refreshAction,
            refreshActionTooltip: "Refresh selectable options (without losing data)",
            backNavUrl,
            enableDelete: true,
            enableCreate: false,
            enableChangelog,
            changelogClicked: enableChangelog ? () => changelogClicked : undefined,
          },
        })
        initializeItem(itemId, fields)
      }
      loadOptions(fields)
      setIsInitialized(true)
    }
  }, [
    match,
    fields,
    apiUrl,
    isInitialized,
    initializeItem,
    loadOptions,
    saveForm,
    createItem,
    deleteItemClicked,
    menuItem,
    dispatch,
    location.pathname,
  ])

  // Prevent refresh or navigation of page if unsaved
  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadFunction)
    return function cleanupListener() {
      window.removeEventListener("beforeunload", beforeUnloadFunction)
    }
  }, [appBarContext.saveEnabled, beforeUnloadFunction])

  // Detect missing required fields
  useEffect(() => {
    if (item && fields) {
      const tempInvalidFields = invalidFields
      fields
        .filter((f) => f.required)
        .forEach((f) => {
          if (!item[f.name]) {
            tempInvalidFields[f.name] = true
            setInvalidFields(tempInvalidFields)
          } else if (invalidFields.hasOwnProperty(f.name)) {
            delete tempInvalidFields[f.name]
            setInvalidFields(tempInvalidFields)
          }
        })
    }
  }, [item, invalidFields, fields])

  // Determine if item has been edited
  useEffect(() => {
    const item = debouncedItem
    let allowSave = false
    if (originalItem && item) {
      allowSave =
        JSON.stringify(originalItem) !== JSON.stringify(item) &&
        Object.keys(debouncedInvalidFields).length < 1
    } else {
      allowSave = item && Object.keys(debouncedInvalidFields).length < 1
    }

    dispatch({
      type: "changeAppBar",
      newAppBar: { saveEnabled: allowSave },
    })
  }, [debouncedItem, debouncedInvalidFields, originalItem, dispatch])

  // Return effect (unmount)
  useEffect(() => {
    return () => {
      dispatch({
        type: "changeAppBar",
        newAppBar: {
          saveAction: undefined,
          saveEnabled: false,
          deleteAction: undefined,
          refreshAction: undefined,
          refreshActionTooltip: undefined,
          enableDelete: false,
          enableChangelog: false,
          searchTerm: "",
          backNavUrl: undefined,
          title: undefined,
        },
      })
    }
  }, [dispatch])

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.root}>
        {displayChangelog && (
          <Changelog
            dismissChangelog={() => setDisplayChangelog(false)}
            item={item}
            params={routeParams}
            apiUrl={apiUrl}
            changelogFetchFailed={changelogFetchFailed}
            initialFields={fields}
          />
        )}
        {alertPromptProps && <AlertPrompt {...alertPromptProps} />}
        {showSnackbar && (
          <SnackbarComponent open={showSnackbar} {...snackbarProps} />
        )}
        {((isLoading || lookupsLoading > 0) && (
          <FormSkeleton
            rows={fields && fields.length ? Math.min(fields.length - 1, 10) : 10}
          />
        )) || (
          <form className={classes.form} autoComplete="off">
            {item &&
              fieldSections.length > 0 &&
              fieldSections.map((s) => {
                return (
                  <Paper
                    elevation={0}
                    key={"grid-" + s[0].headingTitle.toLowerCase()}
                    component={Accordion}
                    defaultExpanded={!s[0].collapsedByDefault}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions3-content"
                      id="additional-actions3-header"
                    >
                      {s.map((f) => {
                        return (
                          f.headingTitle && (
                            <Typography
                              className={classes.sectionHeading}
                              variant="h6"
                              gutterBottom
                              key={f.headingTitle}
                            >
                              {f.headingIcon || ""}
                              {f.headingTitle}
                            </Typography>
                          )
                        )
                      })}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={4} sx={{ alignItems: "flex-end" }}>
                        {s.map((f) => {
                          return (
                            !f.headingTitle && (
                              <React.Fragment key={f.name}>
                                <Grid
                                  item
                                  xs={f.gridXs || 6}
                                  sm={f.gridSm || 6}
                                  md={f.gridMd || 3}
                                  className={`${
                                    itemRef &&
                                    originalItemRef &&
                                    itemRef.current &&
                                    originalItemRef.current &&
                                    itemRef.current[f.name] !==
                                      originalItemRef.current[f.name]
                                      ? classes.modifiedInput
                                      : ""
                                  }`}
                                >
                                  {f.type === "boolean" ? (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={Boolean(Number(item[f.name]))}
                                          onChange={(event) => {
                                            valueChanged(event, f.name)
                                          }}
                                          inputProps={{
                                            ...f.inputProps,
                                            "aria-label": f.title,
                                          }}
                                          disabled={
                                            appBarContext.isSaving || f.disabled
                                          }
                                        />
                                      }
                                      label={f.title}
                                    />
                                  ) : f.type === "autocomplete" && f.selectAll ? (
                                    <MemoizedAutocompleteWithSelectAll
                                      options={options[f.name] || []}
                                      getOptionLabel={(option) => {
                                        return typeof option === "string"
                                          ? option
                                          : option.title
                                      }}
                                      selectedValues={
                                        (item[f.name] &&
                                          options[f.name] &&
                                          (f.json
                                            ? item[f.name]
                                            : options[f.name].filter(
                                                (o) => o.name === item[f.name]
                                              )[0])) ||
                                        null
                                      }
                                      onChange={(value) => {
                                        autocompleteValueChanged(undefined, f.name, {
                                          selectOptions: options[f.name],
                                          value,
                                        })
                                      }}
                                      label={f.title}
                                    />
                                  ) : f.type === "autocomplete" ? (
                                    <MemoizedAutocomplete
                                      multiple={f.multiple}
                                      filterSelectedOptions={f.multiple}
                                      disableCloseOnSelect={f.multiple}
                                      options={options[f.name] || []}
                                      limitTags={10}
                                      size="small"
                                      groupBy={f.groupBy}
                                      value={
                                        (item[f.name] &&
                                          options[f.name] &&
                                          (f.json
                                            ? item[f.name]
                                            : options[f.name].filter(
                                                (o) => o.name === item[f.name]
                                              )[0])) ||
                                        null
                                      }
                                      onChange={(event, value) => {
                                        autocompleteValueChanged(event, f.name, {
                                          selectOptions: options[f.name],
                                          value,
                                        })
                                      }}
                                      getOptionLabel={(option) =>
                                        typeof option === "string"
                                          ? option
                                          : option.title
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        value && option.name === value.name
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                          label={f.title}
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                          }}
                                        />
                                      )}
                                      clearOnBlur={true}
                                    />
                                  ) : f.type === "select" ? (
                                    <div>
                                      <InputLabel
                                        shrink
                                        id={"select-label-" + f.name}
                                      >
                                        {f.title}
                                        &nbsp;test
                                      </InputLabel>
                                      <MemoizedSelect
                                        variant="standard"
                                        className={classes.fullWidth}
                                        labelId={"select-label-" + f.name}
                                        id=""
                                        value={
                                          item[f.name] || options[f.name][0]["name"]
                                        }
                                        disabled={
                                          appBarContext.isSaving || f.disabled
                                        }
                                        onChange={(event) => {
                                          valueChanged(event, f.name)
                                        }}
                                      >
                                        {options[f.name].map((o) => {
                                          return (
                                            <MenuItem key={o.name} value={o.name}>
                                              {o.title}
                                            </MenuItem>
                                          )
                                        })}
                                      </MemoizedSelect>
                                    </div>
                                  ) : f.type === "date" ? (
                                    <MemoizedDatePicker
                                      className={classes.textField}
                                      variant="standard"
                                      label={f.title}
                                      required={f.required}
                                      views={f.datePickerViews}
                                      inputProps={{
                                        ...f.inputProps,
                                        "aria-label": f.title,
                                      }}
                                      InputLabelProps={f.inputLabelProps}
                                      disabled={appBarContext.isSaving || f.disabled}
                                      onChange={(value) => {
                                        valueChangedNoEvent(value, f.name)
                                      }}
                                      value={moment(item[f.name] || null)}
                                      fieldname={f.name}
                                    />
                                  ) : f.type === "list" ? (
                                    <MemoizedListEditor
                                      disabled={appBarContext.isSaving || f.disabled}
                                      onChange={(value) => {
                                        valueChangedNoEvent(value, f.name)
                                      }}
                                      value={item[f.name] || ""}
                                    />
                                  ) : f.type === "plaintext" ? (
                                    <p style={{ whiteSpace: "pre-wrap" }}>
                                      {item[f.name]}
                                    </p>
                                  ) : (
                                    <MemoizedTextField
                                      variant="standard"
                                      className={classes.textField}
                                      label={f.title}
                                      type={f.type}
                                      required={f.required}
                                      inputProps={{
                                        ...f.inputProps,
                                        "aria-label": f.title,
                                      }}
                                      InputProps={{
                                        ...f.InputProps,
                                      }}
                                      InputLabelProps={f.inputLabelProps}
                                      step={f.step}
                                      size="small"
                                      disabled={appBarContext.isSaving || f.disabled}
                                      onChange={(event) => {
                                        valueChanged(event, f.name)
                                      }}
                                      value={
                                        item[f.name] !== null ? item[f.name] : ""
                                      }
                                      fieldname={f.name}
                                      errortext={f.errorText}
                                    />
                                  )}
                                  {f.helperText && (
                                    <FormHelperText>{f.helperText}</FormHelperText>
                                  )}
                                </Grid>
                                {f.skipGridColumns && (
                                  <Grid item xs={f.skipGridColumns}></Grid>
                                )}
                              </React.Fragment>
                            )
                          )
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Paper>
                )
              })}
          </form>
        )}
      </div>
    </StyledEngineProvider>
  )
}

EditForm.propTypes = {
  // match: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default EditForm
