import { Menu } from "../config"

export const countryToFlag = (isoCode) => {
  // Corrections for deviating market codes
  if (isoCode === "uk") {
    isoCode = "gb"
  } else if (isoCode === "ja") {
    isoCode = "jp"
  }

  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode
}

export const getMenuItem = (location) => {
  const url = location && location.pathname ? location.pathname : ""
  if (!url) {
    return false
  }

  // Trim last url segment if numeric (item id)
  const trimmedLocation = url
    .split("?")[0]
    .split("#")[0]
    .split(/(\/\d*)$/)[0]
    .replace(/\/new$/, "")

  let activeMenuItem = false

  for (let i = 0; i < Menu.length; i++) {
    if (Menu[i].link && Menu[i].fields) {
      const link = Menu[i].link
      if (trimmedLocation === link) {
        activeMenuItem = Menu[i]
      }
      if (activeMenuItem) {
        break
      }
    }
    if (Menu[i].items) {
      const subMenu1 = Menu[i].items
      for (let j = 0; j < subMenu1.length; j++) {
        if (subMenu1[j].link && subMenu1[j].fields) {
          const link = subMenu1[j].link
          if (trimmedLocation === link) {
            activeMenuItem = subMenu1[j]
          }
          if (activeMenuItem) {
            break
          }
        }
        if (subMenu1[j].items) {
          const subMenu2 = subMenu1[j].items
          for (let k = 0; k < subMenu2.length; k++) {
            if (subMenu2[k].link && subMenu2[k].fields) {
              const link = subMenu2[k].link
              if (trimmedLocation === link) {
                activeMenuItem = subMenu2[k]
              }
              if (activeMenuItem) {
                break
              }
            }
          }
        }
      }
    }
  }

  return activeMenuItem
}

export const getFields = (location) => {
  const url = location && location.pathname ? location.pathname : ""
  // const url = props && props.match ? props.match.url : ""
  if (!url) {
    return false
  }

  // Trim last url segment if numeric (item id)
  const trimmedLocation = url
    .split("?")[0]
    .split("#")[0]
    .split(/(\/\d*)$/)[0]
    .replace(/\/new$/, "")

  let activeMenuItem = false

  for (let i = 0; i < Menu.length; i++) {
    if (Menu[i].link && Menu[i].fields) {
      const link = Menu[i].link
      if (trimmedLocation === link) {
        activeMenuItem = Menu[i]
      }
      if (activeMenuItem) {
        break
      }
    }
    if (Menu[i].items) {
      const subMenu1 = Menu[i].items
      for (let j = 0; j < subMenu1.length; j++) {
        if (subMenu1[j].link && subMenu1[j].fields) {
          const link = subMenu1[j].link
          if (trimmedLocation === link) {
            activeMenuItem = subMenu1[j]
          }
          if (activeMenuItem) {
            break
          }
        }
        if (subMenu1[j].items) {
          const subMenu2 = subMenu1[j].items
          for (let k = 0; k < subMenu2.length; k++) {
            if (subMenu2[k].link && subMenu2[k].fields) {
              const link = subMenu2[k].link
              if (trimmedLocation === link) {
                activeMenuItem = subMenu2[k]
              }
              if (activeMenuItem) {
                break
              }
            }
          }
        }
      }
    }
  }

  if (activeMenuItem && Array.isArray(activeMenuItem.fields)) {
    return activeMenuItem.fields
  } else {
    return false
  }
}

export const validateTextField = (props) => {
  let invalid = false
  if (props.type === "number") {
    if (
      (props.inputProps.hasOwnProperty("min") &&
        props.value < props.inputProps.min) ||
      (props.inputProps.hasOwnProperty("max") && props.value > props.inputProps.max)
    ) {
      invalid = true
    }
  }

  // Verify that phone number starts with plus sign and is numeric after that
  if (props.inputProps.hasOwnProperty("validatephone") && props.value) {
    invalid = !validatePhoneNumber(props.value)
  }

  return invalid
}

export const validatePhoneNumber = (value) =>
  /^[+()\d-]+$/.test(value) && value.lastIndexOf("+") === 0

export const validateMTTextField = (value, type, inputProps) => {
  let invalid = false
  if (type === "numeric") {
    if (
      (inputProps.hasOwnProperty("min") && value < inputProps.min) ||
      (inputProps.hasOwnProperty("max") && value > inputProps.max)
    ) {
      invalid = true
    }
  }

  return invalid
}

export const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: "created",
    VALUE_UPDATED: "updated",
    VALUE_DELETED: "deleted",
    VALUE_UNCHANGED: "unchanged",
    map: function (obj1, obj2, alwaysIncludeKeys) {
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
        }
      }

      var diff = {}
      for (const key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue
        }

        var value2 = undefined
        if (obj2[key] !== undefined) {
          value2 = obj2[key]
        }

        const keyDiff = this.map(obj1[key], value2)
        if (
          alwaysIncludeKeys.indexOf(key) > -1 ||
          (keyDiff.type !== "unchanged" &&
            keyDiff.type !== "created" &&
            (keyDiff.type === "updated" ||
              (keyDiff.data !== null && keyDiff.data !== 0)))
        ) {
          diff[key] = keyDiff
        }
      }
      for (const key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue
        }

        const keyDiff = this.map(undefined, obj2[key])
        if (
          alwaysIncludeKeys.indexOf(key) > -1 ||
          (keyDiff.type !== "unchanged" &&
            keyDiff.type !== "created" &&
            (keyDiff.type === "updated" ||
              (keyDiff.data !== null && keyDiff.data !== 0)))
        ) {
          diff[key] = keyDiff
        }
      }

      return diff
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED
      }
      if (
        this.isDate(value1) &&
        this.isDate(value2) &&
        value1.getTime() === value2.getTime()
      ) {
        return this.VALUE_UNCHANGED
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED
      }
      return this.VALUE_UPDATED
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === "[object Function]"
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === "[object Array]"
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === "[object Date]"
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === "[object Object]"
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x)
    },
  }
})()

// Check manual positions overlaps
export const isOverlapping = (start1, end1, start2, end2) => {
  const start1Date = new Date(start1)
  const end1Date = new Date(end1)
  const start2Date = new Date(start2)
  const end2Date = new Date(end2)
  return start1Date <= end2Date && start2Date <= end1Date
}
