export const Options = {
  statuses: [
    { name: "1. Active", title: "1. Active" },
    { name: "2. Not launched", title: "2. Not launched" },
    { name: "3. Hidden", title: "3. Hidden" },
    { name: "4. Paused", title: "4. Paused" },
    { name: "5. Out of business", title: "5. Out of business" },
    { name: "6. Blacklisted", title: "6. Blacklisted" },
  ],
  products: [
    { name: "betting", title: "Betting" },
    { name: "bingo", title: "Bingo" },
    { name: "casino", title: "Casino" },
    { name: "crypto-casino", title: "Crypto casino" },
    { name: "esports-betting", title: "Esports" },
    { name: "live-casino", title: "Live casino" },
    { name: "lotto", title: "Lotto" },
    { name: "poker", title: "Poker" },
    { name: "scratch-cards", title: "Scratch cards" },
  ],
  languages: [
    { name: "Afrikaans", title: "Afrikaans", country: "South Africa" },
    { name: "Albanian", title: "Albanian", country: "Albania" },
    { name: "Amharic", title: "Amharic" },
    { name: "Arabic", title: "Arabic" },
    { name: "Aramaic", title: "Aramaic" },
    { name: "Armenian", title: "Armenian", country: "Armenia" },
    { name: "Assamese", title: "Assamese" },
    { name: "Aymara", title: "Aymara" },
    { name: "Azerbaijani", title: "Azerbaijani", country: "Azerbaijan" },
    { name: "Balochi", title: "Balochi" },
    { name: "Bamanankan", title: "Bamanankan" },
    { name: "Bashkort (Bashkir)", title: "Bashkort (Bashkir)" },
    { name: "Basque", title: "Basque", country: "Spain" },
    { name: "Belarusan", title: "Belarusan", country: "Belarus" },
    { name: "Bengali", title: "Bengali" },
    { name: "Bhojpuri", title: "Bhojpuri" },
    { name: "Bislama", title: "Bislama" },
    { name: "Bosnian", title: "Bosnian", country: "Bosnia and Herzegovina" },
    { name: "Brahui", title: "Brahui" },
    { name: "Bulgarian", title: "Bulgarian", country: "Bulgaria" },
    { name: "Burmese", title: "Burmese", country: "Myanmar" },
    { name: "Cantonese", title: "Cantonese" },
    { name: "Catalan", title: "Catalan", country: "Spain" },
    { name: "Cebuano", title: "Cebuano" },
    { name: "Chechen", title: "Chechen" },
    { name: "Cherokee", title: "Cherokee" },
    { name: "Croatian", title: "Croatian", country: "Croatia" },
    { name: "Czech", title: "Czech", country: "Czech Republic" },
    { name: "Dakota", title: "Dakota" },
    { name: "Danish", title: "Danish", country: "Denmark" },
    { name: "Dari", title: "Dari" },
    { name: "Dholuo", title: "Dholuo" },
    { name: "Dutch", title: "Dutch", country: "Netherlands" },
    { name: "English", title: "English", country: "United Kingdom" },
    { name: "Esperanto", title: "Esperanto" },
    { name: "Estonian", title: "Estonian", country: "Estonia" },
    { name: "Éwé", title: "Éwé" },
    { name: "Finnish", title: "Finnish", country: "Finland" },
    { name: "French", title: "French", country: "France" },
    { name: "Georgian", title: "Georgian", country: "Georgia" },
    { name: "German", title: "German", country: "Germany" },
    { name: "Gikuyu", title: "Gikuyu" },
    { name: "Greek", title: "Greek", country: "Greece" },
    { name: "Guarani", title: "Guarani" },
    { name: "Gujarati", title: "Gujarati" },
    { name: "Haitian Creole", title: "Haitian Creole" },
    { name: "Hausa", title: "Hausa" },
    { name: "Hawaiian", title: "Hawaiian" },
    { name: "Hawaiian Creole", title: "Hawaiian Creole" },
    { name: "Hebrew", title: "Hebrew", country: "Israel" },
    { name: "Hiligaynon", title: "Hiligaynon" },
    { name: "Hindi", title: "Hindi", country: "India" },
    { name: "Hungarian", title: "Hungarian", country: "Hungary" },
    { name: "Icelandic", title: "Icelandic", country: "Iceland" },
    { name: "Igbo", title: "Igbo" },
    { name: "Ilocano", title: "Ilocano" },
    {
      name: "Indonesian (Bahasa Indonesia)",
      title: "Indonesian (Bahasa Indonesia)",
    },
    { name: "Inuit/Inupiaq", title: "Inuit/Inupiaq" },
    { name: "Irish Gaelic", title: "Irish Gaelic", country: "Ireland" },
    { name: "Italian", title: "Italian", country: "Italy" },
    { name: "Japanese", title: "Japanese", country: "Japan" },
    { name: "Jarai", title: "Jarai" },
    { name: "Javanese", title: "Javanese", country: "Indonesia" },
    { name: "Kiche", title: "Kiche" },
    { name: "Kabyle", title: "Kabyle" },
    { name: "Kannada", title: "Kannada" },
    { name: "Kashmiri", title: "Kashmiri" },
    { name: "Kazakh", title: "Kazakh", country: "Kazakstan" },
    { name: "Khmer", title: "Khmer" },
    { name: "Khoekhoe", title: "Khoekhoe" },
    { name: "Korean", title: "Korean", country: "South Korea" },
    { name: "Kurdish", title: "Kurdish" },
    { name: "Kyrgyz", title: "Kyrgyz" },
    { name: "Lao", title: "Lao" },
    { name: "Latin", title: "Latin" },
    { name: "Latvian", title: "Latvian", country: "Latvia" },
    { name: "Lingala", title: "Lingala" },
    { name: "Lithuanian", title: "Lithuanian", country: "Lithuania" },
    { name: "Macedonian", title: "Macedonian", country: "Macedonia" },
    { name: "Maithili", title: "Maithili" },
    { name: "Malagasy", title: "Malagasy" },
    { name: "Malay (Bahasa Melayu)", title: "Malay (Bahasa Melayu)" },
    { name: "Malayalam", title: "Malayalam" },
    { name: "Mandarin (Chinese)", title: "Mandarin (Chinese)" },
    { name: "Marathi", title: "Marathi" },
    { name: "Mende", title: "Mende" },
    { name: "Mongolian", title: "Mongolian", country: "Mongolia" },
    { name: "Nahuatl", title: "Nahuatl" },
    { name: "Navajo", title: "Navajo" },
    { name: "Nepali", title: "Nepali", country: "Nepal" },
    { name: "Norwegian", title: "Norwegian", country: "Norway" },
    { name: "Ojibwa", title: "Ojibwa" },
    { name: "Oriya", title: "Oriya" },
    { name: "Oromo", title: "Oromo" },
    { name: "Pashto", title: "Pashto" },
    { name: "Persian", title: "Persian" },
    { name: "Polish", title: "Polish", country: "Poland" },
    { name: "Portuguese", title: "Portuguese", country: "Portugal" },
    { name: "Punjabi", title: "Punjabi" },
    { name: "Quechua", title: "Quechua" },
    { name: "Romani", title: "Romani", country: "Romania" },
    { name: "Romanian", title: "Romanian", country: "Romania" },
    { name: "Russian", title: "Russian", country: "Russia" },
    { name: "Rwanda", title: "Rwanda", country: "Rwanda" },
    { name: "Samoan", title: "Samoan", country: "Samoa" },
    { name: "Sanskrit", title: "Sanskrit" },
    { name: "Serbian", title: "Serbian", country: "Yugoslavia" },
    { name: "Shona", title: "Shona" },
    { name: "Sindhi", title: "Sindhi" },
    { name: "Sinhala", title: "Sinhala" },
    { name: "Slovak", title: "Slovak", country: "Slovakia" },
    { name: "Slovene", title: "Slovene" },
    { name: "Somali", title: "Somali", country: "Somalia" },
    { name: "Spanish", title: "Spanish", country: "Spain" },
    { name: "Swahili", title: "Swahili", country: "Tanzania" },
    { name: "Swedish", title: "Swedish", country: "Sweden" },
    { name: "Tachelhit", title: "Tachelhit" },
    { name: "Tagalog", title: "Tagalog" },
    { name: "Tajiki", title: "Tajiki" },
    { name: "Tamil", title: "Tamil" },
    { name: "Tatar", title: "Tatar" },
    { name: "Telugu", title: "Telugu" },
    { name: "Thai", title: "Thai", country: "Thailand" },
    { name: "Tibetic languages", title: "Tibetic languages" },
    { name: "Tigrigna", title: "Tigrigna" },
    { name: "Tok Pisin", title: "Tok Pisin" },
    { name: "Turkish", title: "Turkish", country: "Turkey" },
    { name: "Turkmen", title: "Turkmen", country: "Turkmenistan" },
    { name: "Ukrainian", title: "Ukrainian", country: "Ukraine" },
    { name: "Urdu", title: "Urdu" },
    { name: "Uyghur", title: "Uyghur" },
    { name: "Uzbek", title: "Uzbek", country: "Uzbekistan" },
    { name: "Vietanamese", title: "Vietnamese", country: "Vietnam" },
    { name: "Warlpiri", title: "Warlpiri" },
    { name: "Welsh", title: "Welsh" },
    { name: "Wolof", title: "Wolof" },
    { name: "Xhosa", title: "Xhosa" },
    { name: "Yakut", title: "Yakut" },
    { name: "Yiddish", title: "Yiddish" },
    { name: "Yoruba", title: "Yoruba" },
    { name: "Yucatec", title: "Yucatec" },
    { name: "Zapotec", title: "Zapotec" },
    { name: "Zulu", title: "Zulu" },
  ],
  countries: [
    { name: "AF", title: "Afghanistan" },
    { name: "AL", title: "Albania" },
    { name: "DZ", title: "Algeria" },
    { name: "AS", title: "American Samoa" },
    { name: "AD", title: "Andorra" },
    { name: "AO", title: "Angola" },
    { name: "AI", title: "Anguilla" },
    { name: "AQ", title: "Antarctica" },
    { name: "AG", title: "Antigua and Barbuda" },
    { name: "AR", title: "Argentina" },
    { name: "AM", title: "Armenia" },
    { name: "AW", title: "Aruba" },
    { name: "AU", title: "Australia" },
    { name: "AT", title: "Austria" },
    { name: "AZ", title: "Azerbaijan" },
    { name: "BS", title: "Bahamas" },
    { name: "BH", title: "Bahrain" },
    { name: "BD", title: "Bangladesh" },
    { name: "BB", title: "Barbados" },
    { name: "BY", title: "Belarus" },
    { name: "BE", title: "Belgium" },
    { name: "BZ", title: "Belize" },
    { name: "BJ", title: "Benin" },
    { name: "BM", title: "Bermuda" },
    { name: "BT", title: "Bhutan" },
    { name: "BO", title: "Bolivia, Plurinational State of" },
    { name: "BQ", title: "Bonaire, Sint Eustatius and Saba" },
    { name: "BA", title: "Bosnia and Herzegovina" },
    { name: "BW", title: "Botswana" },
    { name: "BR", title: "Brazil" },
    { name: "IO", title: "British Indian Ocean Territory" },
    { name: "BN", title: "Brunei Darussalam" },
    { name: "BG", title: "Bulgaria" },
    { name: "BF", title: "Burkina Faso" },
    { name: "BI", title: "Burundi" },
    { name: "KH", title: "Cambodia" },
    { name: "CM", title: "Cameroon" },
    { name: "CA", title: "Canada" },
    { name: "CV", title: "Cape Verde" },
    { name: "KY", title: "Cayman Islands" },
    { name: "CF", title: "Central African Republic" },
    { name: "TD", title: "Chad" },
    { name: "CL", title: "Chile" },
    { name: "CN", title: "China" },
    { name: "CX", title: "Christmas Island" },
    { name: "CC", title: "Cocos (Keeling) Islands" },
    { name: "CI", title: "Cote d'Ivoire" },
    { name: "CO", title: "Colombia" },
    { name: "KM", title: "Comoros" },
    { name: "CG", title: "Congo" },
    { name: "CD", title: "Congo, the Democratic Republic of the" },
    { name: "CK", title: "Cook Islands" },
    { name: "CR", title: "Costa Rica" },
    { name: "HR", title: "Croatia" },
    { name: "CU", title: "Cuba" },
    { name: "CW", title: "Curaçao" },
    { name: "CY", title: "Cyprus" },
    { name: "CZ", title: "Czech Republic" },
    { name: "DK", title: "Denmark" },
    { name: "DJ", title: "Djibouti" },
    { name: "DM", title: "Dominica" },
    { name: "DO", title: "Dominican Republic" },
    { name: "EC", title: "Ecuador" },
    { name: "EG", title: "Egypt" },
    { name: "SV", title: "El Salvador" },
    { name: "GQ", title: "Equatorial Guinea" },
    { name: "ER", title: "Eritrea" },
    { name: "EE", title: "Estonia" },
    { name: "ET", title: "Ethiopia" },
    { name: "FK", title: "Falkland Islands (Malvinas)" },
    { name: "FM", title: "Federated States of Micronesia" },
    { name: "FO", title: "Faroe Islands" },
    { name: "FJ", title: "Fiji" },
    { name: "FI", title: "Finland" },
    { name: "FR", title: "France" },
    { name: "GF", title: "French Guiana" },
    { name: "PF", title: "French Polynesia" },
    { name: "TF", title: "French Southern Territories" },
    { name: "GA", title: "Gabon" },
    { name: "GM", title: "Gambia" },
    { name: "GE", title: "Georgia" },
    { name: "DE", title: "Germany" },
    { name: "GH", title: "Ghana" },
    { name: "GI", title: "Gibraltar" },
    { name: "GR", title: "Greece" },
    { name: "GL", title: "Greenland" },
    { name: "GD", title: "Grenada" },
    { name: "GP", title: "Guadeloupe" },
    { name: "GU", title: "Guam" },
    { name: "GT", title: "Guatemala" },
    { name: "GG", title: "Guernsey" },
    { name: "GN", title: "Guinea" },
    { name: "GW", title: "Guinea-Bissau" },
    { name: "GY", title: "Guyana" },
    { name: "HT", title: "Haiti" },
    { name: "HN", title: "Honduras" },
    { name: "HK", title: "Hong Kong" },
    { name: "HU", title: "Hungary" },
    { name: "IS", title: "Iceland" },
    { name: "IN", title: "India" },
    { name: "ID", title: "Indonesia" },
    { name: "IR", title: "Iran, Islamic Republic of" },
    { name: "IQ", title: "Iraq" },
    { name: "IE", title: "Ireland" },
    { name: "IM", title: "Isle of Man" },
    { name: "IL", title: "Israel" },
    { name: "IT", title: "Italy" },
    { name: "JM", title: "Jamaica" },
    { name: "JP", title: "Japan" },
    { name: "JE", title: "Jersey" },
    { name: "JO", title: "Jordan" },
    { name: "KZ", title: "Kazakhstan" },
    { name: "KE", title: "Kenya" },
    { name: "KI", title: "Kiribati" },
    { name: "KP", title: 'Korea, Democratic People"s Republic of' },
    { name: "KR", title: "Korea, Republic of" },
    { name: "XK", title: "Kosovo" },
    { name: "KW", title: "Kuwait" },
    { name: "KG", title: "Kyrgyzstan" },
    { name: "LA", title: 'Lao People"s Democratic Republic' },
    { name: "LV", title: "Latvia" },
    { name: "LB", title: "Lebanon" },
    { name: "LS", title: "Lesotho" },
    { name: "LR", title: "Liberia" },
    { name: "LY", title: "Libya" },
    { name: "LI", title: "Liechtenstein" },
    { name: "LT", title: "Lithuania" },
    { name: "LU", title: "Luxembourg" },
    { name: "MO", title: "Macao" },
    { name: "MK", title: "Macedonia, the former Yugoslav Republic of" },
    { name: "MG", title: "Madagascar" },
    { name: "MW", title: "Malawi" },
    { name: "MY", title: "Malaysia" },
    { name: "MV", title: "Maldives" },
    { name: "ML", title: "Mali" },
    { name: "MT", title: "Malta" },
    { name: "MH", title: "Marshall Islands" },
    { name: "MQ", title: "Martinique" },
    { name: "MR", title: "Mauritania" },
    { name: "MU", title: "Mauritius" },
    { name: "YT", title: "Mayotte" },
    { name: "MX", title: "Mexico" },
    { name: "MD", title: "Moldova, Republic of" },
    { name: "MC", title: "Monaco" },
    { name: "MN", title: "Mongolia" },
    { name: "ME", title: "Montenegro" },
    { name: "MS", title: "Montserrat" },
    { name: "MA", title: "Morocco" },
    { name: "MZ", title: "Mozambique" },
    { name: "MM", title: "Myanmar" },
    { name: "NA", title: "Namibia" },
    { name: "NR", title: "Nauru" },
    { name: "NP", title: "Nepal" },
    { name: "NL", title: "Netherlands" },
    { name: "NC", title: "New Caledonia" },
    { name: "NZ", title: "New Zealand" },
    { name: "NI", title: "Nicaragua" },
    { name: "NE", title: "Niger" },
    { name: "NG", title: "Nigeria" },
    { name: "NU", title: "Niue" },
    { name: "NF", title: "Norfolk Island" },
    { name: "NO", title: "Norway" },
    { name: "OM", title: "Oman" },
    { name: "PK", title: "Pakistan" },
    { name: "PW", title: "Palau" },
    { name: "PS", title: "Palestinian Territory, Occupied" },
    { name: "PA", title: "Panama" },
    { name: "PG", title: "Papua New Guinea" },
    { name: "PY", title: "Paraguay" },
    { name: "PE", title: "Peru" },
    { name: "PH", title: "Philippines" },
    { name: "PN", title: "Pitcairn" },
    { name: "PL", title: "Poland" },
    { name: "PT", title: "Portugal" },
    { name: "PR", title: "Puerto Rico" },
    { name: "QA", title: "Qatar" },
    { name: "RE", title: "Réunion" },
    { name: "RO", title: "Romania" },
    { name: "RU", title: "Russian Federation" },
    { name: "RW", title: "Rwanda" },
    { name: "BL", title: "Saint Barthélemy" },
    { name: "SH", title: "Saint Helena, Ascension and Tristan da Cunha" },
    { name: "KN", title: "Saint Kitts and Nevis" },
    { name: "LC", title: "Saint Lucia" },
    { name: "PM", title: "Saint Pierre and Miquelon" },
    { name: "VC", title: "Saint Vincent and the Grenadines" },
    { name: "WS", title: "Samoa" },
    { name: "SM", title: "San Marino" },
    { name: "ST", title: "Sao Tome and Principe" },
    { name: "SA", title: "Saudi Arabia" },
    { name: "SN", title: "Senegal" },
    { name: "RS", title: "Serbia" },
    { name: "SC", title: "Seychelles" },
    { name: "SL", title: "Sierra Leone" },
    { name: "SG", title: "Singapore" },
    { name: "SK", title: "Slovakia" },
    { name: "SI", title: "Slovenia" },
    { name: "SB", title: "Solomon Islands" },
    { name: "SO", title: "Somalia" },
    { name: "ZA", title: "South Africa" },
    { name: "SS", title: "South Sudan" },
    { name: "ES", title: "Spain" },
    { name: "LK", title: "Sri Lanka" },
    { name: "SD", title: "Sudan" },
    { name: "SR", title: "Suriname" },
    { name: "SJ", title: "Svalbard and Jan Mayen" },
    { name: "SZ", title: "Swaziland" },
    { name: "SE", title: "Sweden" },
    { name: "CH", title: "Switzerland" },
    { name: "SY", title: "Syrian Arab Republic" },
    { name: "TW", title: "Taiwan, Province of China" },
    { name: "TJ", title: "Tajikistan" },
    { name: "TZ", title: "Tanzania, United Republic of" },
    { name: "TL", title: "Timor-Leste" },
    { name: "TH", title: "Thailand" },
    { name: "TG", title: "Togo" },
    { name: "TK", title: "Tokelau" },
    { name: "TT", title: "Trinidad and Tobago" },
    { name: "TN", title: "Tunisia" },
    { name: "TR", title: "Turkey" },
    { name: "TM", title: "Turkmenistan" },
    { name: "TC", title: "Turks and Caicos Islands" },
    { name: "TV", title: "Tuvalu" },
    { name: "UG", title: "Uganda" },
    { name: "UA", title: "Ukraine" },
    { name: "AE", title: "United Arab Emirates" },
    { name: "GB", title: "United Kingdom" },
    { name: "US", title: "United States" },
    { name: "UM", title: "United States Minor Outlying Islands" },
    { name: "UY", title: "Uruguay" },
    { name: "UZ", title: "Uzbekistan" },
    { name: "VU", title: "Vanuatu" },
    { name: "VE", title: "Venezuela, Bolivarian Republic of" },
    { name: "VN", title: "Viet Nam" },
    { name: "VG", title: "Virgin Islands, British" },
    { name: "VI", title: "Virgin Islands, U.S." },
    { name: "WF", title: "Wallis and Futuna Islands" },
    { name: "YE", title: "Yemen" },
    { name: "ZM", title: "Zambia" },
    { name: "ZW", title: "Zimbabwe" },
  ],
  regions: [
    { name: "ON", title: "Ontario", country: "CA" },
    { name: "QB", title: "Quebec", country: "CA" },
    { name: "AL", title: "Alabama", country: "US" },
    { name: "AK", title: "Alaska", country: "US" },
    { name: "AZ", title: "Arizona", country: "US" },
    { name: "AR", title: "Arkansas", country: "US" },
    { name: "CA", title: "California", country: "US" },
    { name: "CO", title: "Colorado", country: "US" },
    { name: "CT", title: "Connecticut", country: "US" },
    { name: "DE", title: "Delaware", country: "US" },
    { name: "DC", title: "District of Columbia", country: "US" },
    { name: "FL", title: "Florida", country: "US" },
    { name: "GA", title: "Georgia", country: "US" },
    { name: "HI", title: "Hawaii", country: "US" },
    { name: "ID", title: "Idaho", country: "US" },
    { name: "IL", title: "Illinois", country: "US" },
    { name: "IN", title: "Indiana", country: "US" },
    { name: "IA", title: "Iowa", country: "US" },
    { name: "KS", title: "Kansas", country: "US" },
    { name: "KY", title: "Kentucky", country: "US" },
    { name: "LA", title: "Louisiana", country: "US" },
    { name: "ME", title: "Maine", country: "US" },
    { name: "MD", title: "Maryland", country: "US" },
    { name: "MA", title: "Massachusetts", country: "US" },
    { name: "MI", title: "Michigan", country: "US" },
    { name: "MN", title: "Minnesota", country: "US" },
    { name: "MS", title: "Mississippi", country: "US" },
    { name: "MO", title: "Missouri", country: "US" },
    { name: "MT", title: "Montana", country: "US" },
    { name: "NE", title: "Nebraska", country: "US" },
    { name: "NV", title: "Nevada", country: "US" },
    { name: "NH", title: "New Hampshire", country: "US" },
    { name: "NJ", title: "New Jersey", country: "US" },
    { name: "NM", title: "New Mexico", country: "US" },
    { name: "NY", title: "New York", country: "US" },
    { name: "NC", title: "North Carolina", country: "US" },
    { name: "ND", title: "North Dakota", country: "US" },
    { name: "OH", title: "Ohio", country: "US" },
    { name: "OK", title: "Oklahoma", country: "US" },
    { name: "OR", title: "Oregon", country: "US" },
    { name: "PA", title: "Pennsylvania", country: "US" },
    { name: "PR", title: "Puerto Rico", country: "US" },
    { name: "RI", title: "Rhode Island", country: "US" },
    { name: "SC", title: "South Carolina", country: "US" },
    { name: "SD", title: "South Dakota", country: "US" },
    { name: "TN", title: "Tennessee", country: "US" },
    { name: "TX", title: "Texas", country: "US" },
    { name: "UT", title: "Utah", country: "US" },
    { name: "VT", title: "Vermont", country: "US" },
    { name: "VA", title: "Virginia", country: "US" },
    { name: "VI", title: "Virgin Islands", country: "US" },
    { name: "WA", title: "Washington", country: "US" },
    { name: "WV", title: "West Virginia", country: "US" },
    { name: "WI", title: "Wisconsin", country: "US" },
    { name: "WY", title: "Wyoming", country: "US" },
  ],
  affiliateProgramStatuses: [
    // !!! ENUM in DB if you want to add more statuses also modify the DB column
    { name: "Neutral", title: "Neutral" },
    { name: "New", title: "New" },
    { name: "Trustworthy", title: "Trustworthy" },
    { name: "Closed", title: "Closed" },
    { name: "Blacklisted", title: "Blacklisted" },
  ],
  paymentProviderType: [
    { name: "Credit & Debit card", title: "Credit & Debit card" },
    { name: "E-Wallet", title: "E-Wallet" },
    { name: "Instant banking", title: "Instant banking" },
    { name: "Mobile", title: "Mobile" },
    { name: "Cryptocurrency", title: "Cryptocurrency" },
    { name: "Cheque", title: "Cheque" },
    { name: "Bank transfer", title: "Bank transfer" },
    { name: "Prepaid card", title: "Prepaid card" },
  ],
  thirdPartyTestedBy: [
    { name: "quinel", title: "Quinel" },
    { name: "gaming-labs-international", title: "Gaming Labs International" },
    { name: "bmm-testlabs", title: "BMM TestLabs" },
    { name: "itech-labs", title: "iTech Labs" },
    { name: "nmi-metrology-and-gaming-ltd", title: "NMi Metrology & Gaming Ltd" },
    { name: "ecogra", title: "eCOGRA" },
    { name: "technical-services-bureau", title: "Technical Services Bureau" },
  ],
  cryptoCurrencies: [
    { name: "btc", title: "BTC" },
    { name: "bch", title: "BCH" },
    { name: "eth", title: "ETH" },
    { name: "ltc", title: "LTC" },
    { name: "trx", title: "TRX" },
    { name: "tusd", title: "TUSD" },
    { name: "usdt", title: "USDT" },
    { name: "doge", title: "DOGE" },
    { name: "xrp", title: "XRP" },
    { name: "dait", title: "DAI" },
    { name: "dot", title: "DOT" },
    { name: "xmr", title: "XMR" },
    { name: "zec", title: "ZEC" },
    { name: "nem", title: "NEM" },
    { name: "ada", title: "ADA" },
    { name: "sol", title: "SOL" },
    { name: "bnb", title: "BNB" },
  ],
  timespan: [
    { name: "", title: "None" },
    {
      name: "daily",
      title: "Daily",
    },
    { name: "weekly", title: "Weekly" },
    {
      name: "monthly",
      title: "Monthly",
    },
    { name: "yearly", title: "Yearly" },
  ],
  licenseTypes: [
    { name: "local", title: "Local" },
    { name: "global-est", title: "Global Established" },
    { name: "global", title: "Global" },
  ],
  currencies: [
    {
      name: "AFN",
      symbol_native: "؋",
      title: "Afghan Afghani",
    },
    {
      name: "ALL",
      symbol_native: "Lekë",
      title: "Albanian Lek",
    },
    {
      name: "DZD",
      symbol_native: "د.ج.\u200F",
      title: "Algerian Dinar",
    },
    {
      name: "AOA",
      symbol_native: "Kz",
      title: "Angolan Kwanza",
    },
    {
      name: "ARS",
      symbol_native: "$",
      title: "Argentine Peso",
    },
    {
      name: "AMD",
      symbol_native: "֏",
      title: "Armenian Dram",
    },
    {
      name: "AWG",
      symbol_native: "Afl.",
      title: "Aruban Florin",
    },
    {
      name: "AUD",
      symbol_native: "$",
      title: "Australian Dollar",
    },
    {
      name: "AZN",
      symbol_native: "₼",
      title: "Azerbaijani Manat",
    },
    {
      name: "BSD",
      symbol_native: "$",
      title: "Bahamian Dollar",
    },
    {
      name: "BHD",
      symbol_native: "د.ب.\u200F",
      title: "Bahraini Dinar",
    },
    {
      name: "BDT",
      symbol_native: "৳",
      title: "Bangladeshi Taka",
    },
    {
      name: "BBD",
      symbol_native: "$",
      title: "Barbadian Dollar",
    },
    {
      name: "BYN",
      symbol_native: "Br",
      title: "Belarusian Ruble",
    },
    {
      name: "BZD",
      symbol_native: "$",
      title: "Belize Dollar",
    },
    {
      name: "BMD",
      symbol_native: "$",
      title: "Bermudan Dollar",
    },
    {
      name: "BTN",
      symbol_native: "Nu.",
      title: "Bhutanese Ngultrum",
    },
    {
      name: "BOB",
      symbol_native: "Bs",
      title: "Bolivian Boliviano",
    },
    {
      name: "BOV",
      symbol_native: "BOV",
      title: "Bolivian Mvdol",
    },
    {
      name: "BAM",
      symbol_native: "КМ",
      title: "Bosnia-Herzegovina Convertible Mark",
    },
    {
      name: "BWP",
      symbol_native: "P",
      title: "Botswanan Pula",
    },
    {
      name: "BRL",
      symbol_native: "R$",
      title: "Brazilian Real",
    },
    {
      name: "GBP",
      symbol_native: "£",
      title: "British Pound",
    },
    {
      name: "BND",
      symbol_native: "$",
      title: "Brunei Dollar",
    },
    {
      name: "BGN",
      symbol_native: "лв.",
      title: "Bulgarian Lev",
    },
    {
      name: "BIF",
      symbol_native: "FBu",
      title: "Burundian Franc",
    },
    {
      name: "KHR",
      symbol_native: "៛",
      title: "Cambodian Riel",
    },
    {
      name: "CAD",
      symbol_native: "$",
      title: "Canadian Dollar",
    },
    {
      name: "CVE",
      symbol_native: "CVE",
      title: "Cape Verdean Escudo",
    },
    {
      name: "KYD",
      symbol_native: "$",
      title: "Cayman Islands Dollar",
    },
    {
      name: "XAF",
      symbol_native: "FCFA",
      title: "Central African CFA Franc",
    },
    {
      name: "XPF",
      symbol_native: "FCFP",
      title: "CFP Franc",
    },
    {
      name: "CLP",
      symbol_native: "$",
      title: "Chilean Peso",
    },
    {
      name: "CLF",
      symbol_native: "CLF",
      title: "Chilean Unit of Account (UF)",
    },
    {
      name: "CNY",
      symbol_native: "¥",
      title: "Chinese Yuan",
    },
    {
      name: "CNH",
      symbol_native: "CNH",
      title: "Chinese Yuan (offshore)",
    },
    {
      name: "COP",
      symbol_native: "$",
      title: "Colombian Peso",
    },
    {
      name: "COU",
      symbol_native: "COU",
      title: "Colombian Real Value Unit",
    },
    {
      name: "KMF",
      symbol_native: "CF",
      title: "Comorian Franc",
    },
    {
      name: "CDF",
      symbol_native: "FC",
      title: "Congolese Franc",
    },
    {
      name: "CRC",
      symbol_native: "₡",
      title: "Costa Rican Colón",
    },
    {
      name: "HRK",
      symbol_native: "HRK",
      title: "Croatian Kuna",
    },
    {
      name: "CUC",
      symbol_native: "CUC",
      title: "Cuban Convertible Peso",
    },
    {
      name: "CUP",
      symbol_native: "$",
      title: "Cuban Peso",
    },
    {
      name: "CZK",
      symbol_native: "Kč",
      title: "Czech Koruna",
    },
    {
      name: "DKK",
      symbol_native: "kr.",
      title: "Danish Krone",
    },
    {
      name: "DJF",
      symbol_native: "Fdj",
      title: "Djiboutian Franc",
    },
    {
      name: "DOP",
      symbol_native: "RD$",
      title: "Dominican Peso",
    },
    {
      name: "XCD",
      symbol_native: "$",
      title: "East Caribbean Dollar",
    },
    {
      name: "EGP",
      symbol_native: "ج.م.\u200F",
      title: "Egyptian Pound",
    },
    {
      name: "ERN",
      symbol_native: "Nfk",
      title: "Eritrean Nakfa",
    },
    {
      name: "ETB",
      symbol_native: "ብር",
      title: "Ethiopian Birr",
    },
    {
      name: "EUR",
      symbol_native: "€",
      title: "Euro",
    },
    {
      name: "FKP",
      symbol_native: "£",
      title: "Falkland Islands Pound",
    },
    {
      name: "FJD",
      symbol_native: "$",
      title: "Fijian Dollar",
    },
    {
      name: "GMD",
      symbol_native: "D",
      title: "Gambian Dalasi",
    },
    {
      name: "GEL",
      symbol_native: "₾",
      title: "Georgian Lari",
    },
    {
      name: "GHS",
      symbol_native: "GH₵",
      title: "Ghanaian Cedi",
    },
    {
      name: "GIP",
      symbol_native: "£",
      title: "Gibraltar Pound",
    },
    {
      name: "GTQ",
      symbol_native: "Q",
      title: "Guatemalan Quetzal",
    },
    {
      name: "GNF",
      symbol_native: "FG",
      title: "Guinean Franc",
    },
    {
      name: "GYD",
      symbol_native: "$",
      title: "Guyanaese Dollar",
    },
    {
      name: "HTG",
      symbol_native: "G",
      title: "Haitian Gourde",
    },
    {
      name: "HNL",
      symbol_native: "L",
      title: "Honduran Lempira",
    },
    {
      name: "HKD",
      symbol_native: "HK$",
      title: "Hong Kong Dollar",
    },
    {
      name: "HUF",
      symbol_native: "Ft",
      title: "Hungarian Forint",
    },
    {
      name: "ISK",
      symbol_native: "ISK",
      title: "Icelandic Króna",
    },
    {
      name: "INR",
      symbol_native: "₹",
      title: "Indian Rupee",
    },
    {
      name: "IDR",
      symbol_native: "Rp",
      title: "Indonesian Rupiah",
    },
    {
      name: "IRR",
      symbol_native: "IRR",
      title: "Iranian Rial",
    },
    {
      name: "IQD",
      symbol_native: "د.ع.\u200F",
      title: "Iraqi Dinar",
    },
    {
      name: "ILS",
      symbol_native: "₪",
      title: "Israeli New Shekel",
    },
    {
      name: "JMD",
      symbol_native: "$",
      title: "Jamaican Dollar",
    },
    {
      name: "JPY",
      symbol_native: "￥",
      title: "Japanese Yen",
    },
    {
      name: "JOD",
      symbol_native: "د.أ.\u200F",
      title: "Jordanian Dinar",
    },
    {
      name: "KZT",
      symbol_native: "₸",
      title: "Kazakhstani Tenge",
    },
    {
      name: "KES",
      symbol_native: "Ksh",
      title: "Kenyan Shilling",
    },
    {
      name: "KWD",
      symbol_native: "د.ك.\u200F",
      title: "Kuwaiti Dinar",
    },
    {
      name: "KGS",
      symbol_native: "сом",
      title: "Kyrgystani Som",
    },
    {
      name: "LAK",
      symbol_native: "₭",
      title: "Laotian Kip",
    },
    {
      name: "LBP",
      symbol_native: "ل.ل.\u200F",
      title: "Lebanese Pound",
    },
    {
      name: "LSL",
      symbol_native: "LSL",
      title: "Lesotho Loti",
    },
    {
      name: "LRD",
      symbol_native: "$",
      title: "Liberian Dollar",
    },
    {
      name: "LYD",
      symbol_native: "د.ل.\u200F",
      title: "Libyan Dinar",
    },
    {
      name: "MOP",
      symbol_native: "MOP$",
      title: "Macanese Pataca",
    },
    {
      name: "MKD",
      symbol_native: "ден",
      title: "Macedonian Denar",
    },
    {
      name: "MGA",
      symbol_native: "Ar",
      title: "Malagasy Ariary",
    },
    {
      name: "MWK",
      symbol_native: "MK",
      title: "Malawian Kwacha",
    },
    {
      name: "MYR",
      symbol_native: "RM",
      title: "Malaysian Ringgit",
    },
    {
      name: "MRO",
      symbol_native: "أ.م.\u200F",
      title: "Mauritanian Ouguiya",
    },
    {
      name: "MUR",
      symbol_native: "Rs",
      title: "Mauritian Rupee",
    },
    {
      name: "MXV",
      symbol_native: "MXV",
      title: "Mexican Investment Unit",
    },
    {
      name: "MXN",
      symbol_native: "$",
      title: "Mexican Peso",
    },
    {
      name: "MDL",
      symbol_native: "L",
      title: "Moldovan Leu",
    },
    {
      name: "MNT",
      symbol_native: "₮",
      title: "Mongolian Tugrik",
    },
    {
      name: "MAD",
      symbol_native: "د.م.\u200F",
      title: "Moroccan Dirham",
    },
    {
      name: "MZN",
      symbol_native: "MTn",
      title: "Mozambican Metical",
    },
    {
      name: "MMK",
      symbol_native: "K",
      title: "Myanmar Kyat",
    },
    {
      name: "NAD",
      symbol_native: "$",
      title: "Namibian Dollar",
    },
    {
      name: "NPR",
      symbol_native: "नेरू",
      title: "Nepalese Rupee",
    },
    {
      name: "ANG",
      symbol_native: "NAf.",
      title: "Netherlands Antillean Guilder",
    },
    {
      name: "TWD",
      symbol_native: "$",
      title: "New Taiwan Dollar",
    },
    {
      name: "NZD",
      symbol_native: "$",
      title: "New Zealand Dollar",
    },
    {
      name: "NIO",
      symbol_native: "C$",
      title: "Nicaraguan Córdoba",
    },
    {
      name: "NGN",
      symbol_native: "₦",
      title: "Nigerian Naira",
    },
    {
      name: "KPW",
      symbol_native: "KPW",
      title: "North Korean Won",
    },
    {
      name: "NOK",
      symbol_native: "kr",
      title: "Norwegian Krone",
    },
    {
      name: "OMR",
      symbol_native: "ر.ع.\u200F",
      title: "Omani Rial",
    },
    {
      name: "PKR",
      symbol_native: "Rs",
      title: "Pakistani Rupee",
    },
    {
      name: "PAB",
      symbol_native: "B/.",
      title: "Panamanian Balboa",
    },
    {
      name: "PGK",
      symbol_native: "K",
      title: "Papua New Guinean Kina",
    },
    {
      name: "PYG",
      symbol_native: "Gs.",
      title: "Paraguayan Guarani",
    },
    {
      name: "PEN",
      symbol_native: "S/",
      title: "Peruvian Sol",
    },
    {
      name: "PHP",
      symbol_native: "₱",
      title: "Philippine Piso",
    },
    {
      name: "PLN",
      symbol_native: "zł",
      title: "Polish Zloty",
    },
    {
      name: "QAR",
      symbol_native: "ر.ق.\u200F",
      title: "Qatari Rial",
    },
    {
      name: "RON",
      symbol_native: "RON",
      title: "Romanian Leu",
    },
    {
      name: "RUB",
      symbol_native: "₽",
      title: "Russian Ruble",
    },
    {
      name: "RWF",
      symbol_native: "RF",
      title: "Rwandan Franc",
    },
    {
      name: "WST",
      symbol_native: "WS$",
      title: "Samoan Tala",
    },
    {
      name: "STN",
      symbol_native: "STN",
      title: "São Tomé &amp; Príncipe Dobra (2018)",
    },
    {
      name: "SAR",
      symbol_native: "ر.س.\u200F",
      title: "Saudi Riyal",
    },
    {
      name: "RSD",
      symbol_native: "RSD",
      title: "Serbian Dinar",
    },
    {
      name: "SCR",
      symbol_native: "SR",
      title: "Seychellois Rupee",
    },
    {
      name: "SLL",
      symbol_native: "Le",
      title: "Sierra Leonean Leone",
    },
    {
      name: "SGD",
      symbol_native: "$",
      title: "Singapore Dollar",
    },
    {
      name: "SBD",
      symbol_native: "$",
      title: "Solomon Islands Dollar",
    },
    {
      name: "SOS",
      symbol_native: "S",
      title: "Somali Shilling",
    },
    {
      name: "ZAR",
      symbol_native: "R",
      title: "South African Rand",
    },
    {
      name: "KRW",
      symbol_native: "₩",
      title: "South Korean Won",
    },
    {
      name: "SSP",
      symbol_native: "£",
      title: "South Sudanese Pound",
    },
    {
      name: "LKR",
      symbol_native: "රු.",
      title: "Sri Lankan Rupee",
    },
    {
      name: "SHP",
      symbol_native: "£",
      title: "St. Helena Pound",
    },
    {
      name: "SDG",
      symbol_native: "ج.س.",
      title: "Sudanese Pound",
    },
    {
      name: "SRD",
      symbol_native: "$",
      title: "Surinamese Dollar",
    },
    {
      name: "SZL",
      symbol_native: "E",
      title: "Swazi Lilangeni",
    },
    {
      name: "SEK",
      symbol_native: "kr",
      title: "Swedish Krona",
    },
    {
      name: "CHF",
      symbol_native: "CHF",
      title: "Swiss Franc",
    },
    {
      name: "SYP",
      symbol_native: "ل.س.\u200F",
      title: "Syrian Pound",
    },
    {
      name: "TJS",
      symbol_native: "сом.",
      title: "Tajikistani Somoni",
    },
    {
      name: "TZS",
      symbol_native: "TSh",
      title: "Tanzanian Shilling",
    },
    {
      name: "THB",
      symbol_native: "THB",
      title: "Thai Baht",
    },
    {
      name: "TOP",
      symbol_native: "T$",
      title: "Tongan Paʻanga",
    },
    {
      name: "TTD",
      symbol_native: "$",
      title: "Trinidad &amp; Tobago Dollar",
    },
    {
      name: "TND",
      symbol_native: "د.ت.\u200F",
      title: "Tunisian Dinar",
    },
    {
      name: "TRY",
      symbol_native: "₺",
      title: "Turkish Lira",
    },
    {
      name: "UGX",
      symbol_native: "USh",
      title: "Ugandan Shilling",
    },
    {
      name: "UAH",
      symbol_native: "₴",
      title: "Ukrainian Hryvnia",
    },
    {
      name: "AED",
      symbol_native: "د.إ.\u200F",
      title: "United Arab Emirates Dirham",
    },
    {
      name: "UYU",
      symbol_native: "$",
      title: "Uruguayan Peso",
    },
    {
      name: "UYI",
      symbol_native: "UYI",
      title: "Uruguayan Peso (Indexed Units)",
    },
    {
      name: "USD",
      symbol_native: "$",
      title: "US Dollar",
    },
    {
      name: "USN",
      symbol_native: "USN",
      title: "US Dollar (Next day)",
    },
    {
      name: "UZS",
      symbol_native: "сўм",
      title: "Uzbekistani Som",
    },
    {
      name: "VUV",
      symbol_native: "VT",
      title: "Vanuatu Vatu",
    },
    {
      name: "VEF",
      symbol_native: "Bs.",
      title: "Venezuelan Bolívar",
    },
    {
      name: "VND",
      symbol_native: "₫",
      title: "Vietnamese Dong",
    },
    {
      name: "XOF",
      symbol_native: "CFA",
      title: "West African CFA Franc",
    },
    {
      name: "CHE",
      symbol_native: "CHE",
      title: "WIR Euro",
    },
    {
      name: "CHW",
      symbol_native: "CHW",
      title: "WIR Franc",
    },
    {
      name: "YER",
      symbol_native: "ر.ي.\u200F",
      title: "Yemeni Rial",
    },
    {
      name: "ZMW",
      symbol_native: "K",
      title: "Zambian Kwacha",
    },
  ],
}
